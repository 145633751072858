<template>
  <div class="tnd-table-list">
    <div class="group" v-for="(group, index) of data" :key="index">
      <div class="group__title">
        {{ group.name }}
      </div>
      <div
        class="columns is-gapless is-align-items-center"
        v-for="(param, i) of group.params"
        :key="i"
      >
        <div
          class="column is-one-quarter name"
          :style="{ color: param.nameColor }"
        >
          {{ param.name }}
        </div>
        <div
          class="column is-one-quarter status"
          v-if="param.type === 'status'"
        >
          <order-status :statusId="orderStatusId" />
        </div>
        <div
          class="column is-one-quarter status"
          v-if="param.type === 'tag'"
          v-html="param.value"
        ></div>
        <div class="column is-one-quarter value" v-if="!param.type">
          {{ param.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderStatus from "@/components/common/OrderStatus.vue";

export default {
  components: { OrderStatus },
  name: "TndTAbleList",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    orderStatusId() {
      return this.$store.getters["tenderRequest/orderStatusId"];
    },
  },
};
</script>

<style lang="scss">
.tnd-table-list {
  .group {
    padding-top: 38px;
    &__title {
      margin-bottom: 35px;
      color: #323232;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
    }

    .name {
      color: #6f6f6f;
      font-weight: 400;
      font-size: 14px;
      line-height: 1em;
      text-transform: uppercase;
    }

    .status {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: start;
      }
    }

    .value {
      color: #323232;
      font-weight: 500;
      font-size: 1em;
      line-height: 1.2em;
    }
  }
}
</style>