<template>
  <div class="tnd-pre-price">
    <div class="info-container">
        <div class="info">
          <div class="info__icon">{{currencySymbol}}</div>
          <div class="info__text">ПРЕДВАРИТЕЛЬНАЯ СТОИМОСТЬ С НДС:</div>
        </div>
        <div class="price">{{value}}</div>
    </div>
    
    </div>
</template>

<script>
export default {
  name: 'TndPerPrice',
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    currencySymbol: {
      type: String,
      default: '₽'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.tnd-pre-price {
    display: inline-block;
    background: linear-gradient(94.43deg, #A2165B 0%, #5A004B 100%);
    padding: 3px;
    border-radius: 6px;

    .info-container {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }


      .info {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: #fff;
      border-radius: 6px 0px 0px 6px;
      flex: 1;
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: linear-gradient(94.43deg, #A2165B 0%, #5A004B 100%);
        color: #FFFFFF;
        font-weight: 100;
        font-size: 12px;
        margin-right:0.5em;
      }
      &__text {
        background: -webkit-linear-gradient(94.43deg, #A2165B 0%, #5A004B 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        
      }
      
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 13px;
      color: #FFFFFF; //TODO добавить цвет в переменную
      font-weight: 500;
      font-size: 1em;
      line-height: 21px;
    }
    
    }
    
  }

</style>