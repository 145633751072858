export const orderStatusIds = {
    DRAFT: { id: 1, title: 'Черновик', bgColor: "status-draft" },
    NEW: { id: 2, title: 'Сформирован', bgColor: "has-background-link-light" },
    NEW1: { id: 3, title: 'Открыт', bgColor: "status-open" },
    NEW2: { id: 4, title: 'Отменен', bgColor: "has-background-success-light" },
    NEW3: { id: 5, title: 'Подведение итогов', bgColor: "has-background-warning-light" },
    NEW4: { id: 6, title: 'Окончен', bgColor: "has-background-danger-light" },
    NEW5: { id: 7, title: 'Заполнение сценария', bgColor: "has-background-primary-light" },
    NEW6: { id: 8, title: 'Проведение аукциона', bgColor: "has-background-link-light" },
    NEW7: { id: 10, title: 'Черновик', bgColor: "status-draft" },
    NEW8: { id: 11, title: 'Подана', bgColor: "has-background-success-light" },
    NEW9: { id: 12, title: 'Конкурс', bgColor: "status-contest" },
    NEW10: { id: 13, title: 'Принята к рассмотрению', bgColor: "status-pending" },
    NEW11: { id: 14, title: 'Отклонена', bgColor: "status-declined" },
    NEW12: { id: 15, title: 'Победитель', bgColor: "status-winner" },
    NEW13: { id: 16, title: 'Переторг', bgColor: "status-bargain" },
    NEW14: { id: 17, title: 'На редактировании', bgColor: "has-background-success-light" },
    NEW15: { id: 18, title: 'На проверке', bgColor: "has-background-success-light" },
    NEW17: { id: 19, title: 'Проверен', bgColor: "has-background-success-light" },
    NEW18: { id: 20, title: 'Заблокирован', bgColor: "has-background-success-light" },
}

export const tenderStatusIds = {
    STATUS_DRAFT: { id: 1, title: 'Черновик', bgColor: "status-draft" },
    STATUS_OPEN: { id: 2, title: 'Открыт', bgColor: "status-open" },
    STATUS_CANCELED: { id: 3, title: 'Отменен', bgColor: "has-background-info-light" },
    STATUS_SUMMARIZING: { id: 4, title: 'Подведение итогов', bgColor: "has-background-success-light" },
    STATUS_FINISHED: { id: 5, title: 'Окончен', bgColor: "has-background-warning-light" },
    STATUS_FORMED: { id: 6, title: 'Сформирован', bgColor: "has-background-danger-light" },
    STATUS_FILLING_SCENARIO: { id: 7, title: 'Заполнение сценария', bgColor: "has-background-primary-light" },
    STATUS_AUCTION: { id: 8, title: 'Проведение аукциона', bgColor: "has-background-link-light" },
    STATUS_PENDING_APPROVAL: { id: 9, title: 'Проведение согласования', bgColor: "has-background-info-light" },
}

/*
    self::ACTION_STATUS_DRAFT => 'Черновик',
    self::ACTION_STATUS_OPEN => 'Открыт',
    self::ACTION_STATUS_CANCELED => 'Отменен',
    self::ACTION_STATUS_SUMMARIZING => 'Подведение итогов',
    self::ACTION_STATUS_FINISHED => 'Окончен',
    self::ACTION_STATUS_FORMED => 'Сформирован',
    self::ACTION_STATUS_FILLING_SCENARIO => 'Заполнение сценария',
    self::ACTION_STATUS_AUCTION => 'Проведение аукциона',
    self::ACTION_STATUS_PENDING_APPROVAL => 'Проведение согласования',

    const ACTION_STATUS_DRAFT = 1;
    const ACTION_STATUS_OPEN = 2;
    const ACTION_STATUS_CANCELED = 3;
    const ACTION_STATUS_SUMMARIZING = 4;
    const ACTION_STATUS_FINISHED = 5;
    const ACTION_STATUS_FORMED = 6;
    const ACTION_STATUS_FILLING_SCENARIO = 7;
    const ACTION_STATUS_AUCTION = 8;
    const ACTION_STATUS_PENDING_APPROVAL = 9;
*/
